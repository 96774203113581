import React, { useContext } from "react";
import { MenuContext } from "../../context";

const FindOutMoreBtn = ({ additionalClass, goTo, buttonText, onClick, download = false }) => {
  const menuContext = useContext(MenuContext);

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <section className={`find-out-more-btn ${additionalClass || ""}`}>
      <a
        href={goTo}
        target="_self"
        inert={menuContext.isMenuOpen ? "true" : null}
        download={download ? "" : undefined}
        onClick={handleClick}
      >
        {buttonText ? buttonText : "Find out more"}
      </a>
    </section>
  );
};

export default FindOutMoreBtn;
