import React from 'react';
import data from '../../data/company-list.json';
import { triggerTealiumEvent } from "../../utils/tealiumUtils";
import { useLocation } from "@reach/router";

export default function CompanyList() {

  const location = useLocation();
  const handleClick = ({ eventContent, eventAction, rawDataLayer }) => {
    if (typeof window !== "undefined" && window.utag) {
      triggerTealiumEvent({
        tealiumEvent: "link",
        pageUrl: location.href,
        pageName:"complaints",
        eventCategory: "content",
        eventAction: eventAction,
        eventContent: eventContent,
        pageType: "product information",
        pageSubCategory: "complaints",
        rawDataLayer: rawDataLayer,
      });
    }
  };

  return (
    <div className="splitted-section company">
      {Object.keys(data).map((key) => (
        <div key={key} className="splitted-section--third pr-5">
          {data[key].map((item, index) => (
            <div key={index} className="margin-bottom-half">
              <span className='company__name'><strong>{item.title}</strong></span> <br />
              {item.internal === "true" ?
                <span className='company__link'>
                  <a 
                    href={item.href} 
                    target='_blank'
                    onClick={() =>
                      handleClick({
                        eventContent: `Complain: ${item.title}`,
                        eventAction: "offsite",
                        rawDataLayer: "10451v42",
                      })
                    }>
                      https://intermediaries.hsbc.co.uk{item.href}
                  </a>
                </span>
                :
                <span className='company__link'>
                  <a 
                    href={item.href} 
                    target='_blank'
                    onClick={() =>
                      handleClick({
                        eventContent: `Complain: ${item.title}`,
                        eventAction: "offsite",
                        rawDataLayer: "10451v42",
                      })
                    }>
                      {item.href}
                  </a>
                </span>}
              {/* <span className='company__link'><a href={item.href} target='_blank'>{item.href}</a></span> */}
              <span className='company__link'>{item.text}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}