import React from 'react';
import AwardList from '../components/award-list/award-list.component';
import AWARDS from '../components/award-panel/awards.utils';
import Layout from '../components/layout';
import { triggerTealiumEvent } from "../utils/tealiumUtils";
import { useLocation } from "@reach/router";
import '../styles/app.scss';

export default function AwardPage() {

  const location = useLocation();
  if (typeof window !== "undefined" && window.utag) {
    triggerTealiumEvent({
      tealiumEvent: "view",
      pageUrl: location.href,
      pageType: "product information",
      pageName: "awards",
      pageSubCategory: "awards",
      rawDataLayer: "10451v61",
    });
  }


  return (
    <Layout
      title='Our award success'
      metaDescription="Our achievements to date are a testament to the great relationships we have with our Intermediary Partners, and of course supported by our fantastic products and service."
    >
      <div className="page-section row">
        <div className='col-xs-12 col-lg-7'>
          <h1 className='page-title'>Our Award Success</h1>
          <p>Our achievements to date are a testament to the great relationships we have with our Intermediary Partners, and of course supported by our fantastic products and service.</p>
          <AwardList
            awardItems={AWARDS}
            additionalClass='col-xs-12 col-lg-12'
            orientation='horizontal'
          />
        </div>
      </div>
    </Layout>
  );
}