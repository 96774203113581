import React, { useContext } from "react";
import GeneralAnchor from "../general-anchor/general-anchor.component";
import { MenuContext } from "../../context";
import ContactSvg from "../../assets/contact.inline.svg";
import PhoneSvg from "../../assets/phone.inline.svg";

const ContactUs = () => {
  const menuContext = useContext(MenuContext);
  return (
    <section className="contact-us">
      <h2>Contact us</h2>
      <GeneralAnchor
        isBlank
        href="/broker-support"
        rel="noreferrer"
        isLink={false}
        noClass={true}
        inert={menuContext.isMenuOpen ? "true" : null}
      >
        <PhoneSvg />
        Call our help desk
      </GeneralAnchor>

      <GeneralAnchor
        isBlank
        href="/broker-support"
        rel="noreferrer"
        isLink={false}
        noClass={true}
        inert={menuContext.isMenuOpen ? "true" : null}
      >
        <ContactSvg />
        Find a BDM
      </GeneralAnchor>
    </section>
  );
};

export default ContactUs;
