import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20250113-Rate-Sheet.pdf";
const date = "13.01.2025";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>
    <p>With effect from Tuesday Monday 13 January 2025 we will be making the following changes to our range:</p>
    <p>
      <strong>Summary of changes</strong>
    </p>
    <p>
      We are pleased to be introducing a 2 Year Premier Exclusive Range with a &pound;999 fee for Residential Purchase,
      Remortgage and Existing customers (This does not include the EEH range). This is for UK and International
      applications. The booking fee for the 5 Year Premier Exclusive Range will reduce to &pound;999.
    </p>
    <p>
      <strong>UK Residential Existing Customer Switching Range</strong>
    </p>
    <ul>
      <li>5 Year Fixed Premier Exclusive at 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
    </ul>
    <p>
      <strong>UK Residential Existing Customer Borrowing More Range</strong>
    </p>
    <ul>
      <li>5 Year Fixed Premier Exclusive at 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
    </ul>
    <p>
      <strong>UK Residential First Time Buyer Range</strong>
    </p>
    <ul>
      <li>5 Year Fixed Premier Exclusive at 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
    </ul>
    <p>
      <strong>UK Residential Home Mover Range</strong>
    </p>
    <ul>
      <li>5 Year Fixed Premier Exclusive at 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
    </ul>
    <p>
      <strong>UK Residential Remortgage Range</strong>
    </p>
    <ul>
      <li>5 Year Fixed Premier Exclusive at 70%, 75%, 80%, 85% and 90% LTV have decreased</li>
    </ul>
    <p>
      <strong>International Residential Range</strong>
    </p>
    <ul>
      <li>5 Year Fixed Premier Exclusive at 70% and 75% LTV have decreased</li>
    </ul>
    <p>There are no others changes to our interest rates.</p>
    <p>An updated mortgage rate sheet will be published on Monday 13 January 2025 to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
