import React, { useContext } from "react";
import { Link } from "gatsby";
import { triggerTealiumEvent } from "../../utils/tealiumUtils";
import { useLocation } from "@reach/router";
import { MenuContext } from "../../context";

const GeneralAnchor = ({
  children,
  isBlank,
  href,
  isLink = false,
  target,
  ariaLabel = "",
  referrer,
  isRed,
  downloadTitle,
  onClick,
  additionalClass,
  noClass = false,
}) => {
  const menuContext = useContext(MenuContext);
  const location = useLocation();

  const extractTextFromChildren = (children) => {
    switch (true) {
      case typeof children === "string":
        return children;
      case React.isValidElement(children):
        return extractTextFromChildren(children.props.children);
      case Array.isArray(children):
        return children.map(extractTextFromChildren).join(" ");
      default:
        return "";
    }
  };

  const handleClick = (e) => {
    if (typeof window === "undefined" || !window.utag) return;

    const textContent = extractTextFromChildren(children).trim();
    const commonData = {
      tealiumEvent: "link",
      pageUrl: location.href,
      eventCategory: "content",
      pageType: "product information",
    };

    const events = {
      "/resources": {
        eventAction: "download",
        eventContent: `download: ${textContent.replace(/\s*\(.*?\)$/, "")}`,
        pageName: "resources",
        pageSubCategory: "resources",
        rawDataLayer: "10451v51",
      },
      "/packaging-requirements": isLink
        ? {
            eventAction: "download",
            eventContent: `download: ${textContent.replace(/\s*\(.*?\)$/, "")}`,
            pageName: "packaging requirements",
            pageSubCategory: "packaging requirements",
            rawDataLayer: "10451v77",
          }
        : {
            eventAction: "onsite",
            eventContent: `contact us | packaging requirements : ${textContent}`,
            pageName: "packaging requirements",
            pageSubCategory: "packaging requirements",
            rawDataLayer: "10451v75",
          },
      "/broker-support": {
        eventAction: "onsite",
        eventContent: `support actions | broker support : ${textContent}`,
        pageName: "broker support",
        pageSubCategory: "broker support",
        rawDataLayer: "10451v33",
      },
      "/placing-business": href.includes("frequently-asked-questions")
        ? {
            eventAction: "offsite",
            eventContent: `placing business : ${textContent}`,
            pageName: "placing business",
            pageSubCategory: "placing business",
            rawDataLayer: "10451v56",
          }
        : {
            eventAction: "download",
            eventContent: `download: ${textContent.replace(/\s*\(.*?\)$/, "")}`,
            pageName: "placing business",
            pageSubCategory: "placing business",
            rawDataLayer: "10451v55",
          },
      "/newsroom": href.includes("pdfs")
        ? {
            eventAction: "onsite",
            eventContent: `news : ${textContent}`,
            pageName: "newsroom",
            pageSubCategory: "newsroom",
            rawDataLayer: "10451v24",
          }
        : {
            eventAction: "offsite",
            eventContent: `news : ${textContent}`,
            pageName: "newsroom",
            pageSubCategory: "newsroom",
            rawDataLayer: "10451v25",
          },
    };

    if (location.href.includes("essential-reading")) {
      events[location.pathname] = {
        eventAction: "onsite",
        eventContent: `contact us | ${location.href.replace(/\/$/, "").split("/").pop()} : ${textContent}`,
        pageName: "essential reading",
        pageSubCategory: "newsroom",
        rawDataLayer: "10451v29",
      };
    }

    const eventData =
      events[location.pathname] || events[`${location.pathname}/`] || events[`${location.pathname.replace(/\/$/, "")}`];

    if (eventData) {
      triggerTealiumEvent({ ...commonData, ...eventData });
    }

    if (onClick) {
      onClick(e);
    }
  };

  return (
    <>
      {isLink === true ? (
        <a
          className={`${(additionalClass && additionalClass) || ""}${
            (noClass !== true && " general-anchor-btn") || ""
          }${(isRed && " general-anchor-btn--red") || ""}`}
          href={href}
          target={target ? target : isBlank ? "_blank" : "_self"}
          rel={referrer ? referrer : undefined}
          download={downloadTitle}
          aria-label={ariaLabel}
          onClick={handleClick}
          inert={menuContext.isMenuOpen ? "true" : null}
        >
          {children}
        </a>
      ) : (
        <Link
          className={`${(additionalClass && additionalClass) || ""}${
            (noClass !== true && " general-anchor-btn") || ""
          }${(isRed && " general-anchor-btn--red") || ""}`}
          to={href}
          target={target ? target : isBlank ? "_blank" : "_self"}
          rel={referrer ? referrer : undefined}
          download={downloadTitle}
          aria-label={ariaLabel}
          onClick={handleClick}
          inert={menuContext.isMenuOpen ? "true" : null}
        >
          {children}
        </Link>
      )}
    </>
  );
};

export default GeneralAnchor;
