import React from "react";
import RateSheet from "../rate-sheet/rate-sheet.component";
import Layout from "../layout";
import ContactUs from "../contact-us/contact-us.component";
import { triggerTealiumEvent } from "../../utils/tealiumUtils";
import { useLocation } from "@reach/router";
import "../../styles/app.scss";

export default function NewsArticleLayout({ children, title, description, isNew, href, date }) {
  const location = useLocation();
  
  const handleClick = ({ sectionName, clickedText }) => {
    const lastSegment = location.href.replace(/\/$/, "").split("/").pop();
    
    if (typeof window !== "undefined" && window.utag) {
      triggerTealiumEvent({
        tealiumEvent: "link",
        pageUrl: location.href,
        pageName: `newsroom:essential reading:${lastSegment}`,
        eventCategory: "content",
        eventAction: "onsite",
        eventContent: `${sectionName} | ${lastSegment} : ${clickedText}`,
        pageType: "product information",
        pageSubCategory: "newsroom",
        rawDataLayer: "10451v29",
      });
    }
  };

  return (
    <Layout title={title} metaDescription={description}>
      <section className="news-article page-section row">
        <div className="news-article__body col-xs-12 col-lg-7">{children}</div>
        <div className="news-article__sidebar col-xs-12 col-lg-4 col-lg-offset-1">
          <ContactUs />
          {isNew ? (
            <RateSheet
              href={href}
              date={date}
              onClick={() => handleClick({ sectionName: "Rate sheet", clickedText: "Rate sheet" })}
            />
          ) : null}
        </div>
      </section>
    </Layout>
  );
}
