export const triggerTealiumEvent = ({
  siteCountry = "UK",
  tealiumEvent = "",
  pageUrl = "",
  eventCategory = "",
  eventAction = "",
  eventContent = "",
  siteSection = "pws",
  pageLanguage = "en",
  pageType = "",
  pageCustomerGroup = "general",
  pageSecurityLevel = "0",
  pageName = "",
  pageCategory = "intermediaries.hsbc.co.uk",
  pageSubCategory = "",
  rawDataLayer = "",
} = {}) => {
  if (typeof window !== "undefined" && window.utag) {
    /*
    console.log("site_country:", siteCountry);
    console.log("tealium_event:", tealiumEvent);
    console.log("page_url:", pageUrl);
    console.log("event_category:", eventCategory);
    console.log("event_action:", eventAction);
    console.log("event_content:", eventContent);
    console.log("site_section:", siteSection);
    console.log("page_language:", pageLanguage);
    console.log("page_type:", pageType);
    console.log("page_customer_group:", pageCustomerGroup);
    console.log("page_security_level:", pageSecurityLevel);
    console.log("page_name:", pageName === "" ? "pws:" + pageCategory : "pws:" + pageCategory + ":" + pageName);
    console.log("page_category:", pageCategory);
    console.log("page_subcategory:", pageSubCategory);
    console.log("raw_datalayer:", rawDataLayer);
    */
    window.utag_data = {
      site_country: siteCountry,
      tealium_event: tealiumEvent,
      page_url: pageUrl,
      event_category: eventCategory,
      event_action: eventAction,
      event_content: eventContent,
      site_section: siteSection,
      page_language: pageLanguage,
      page_type: pageType,
      page_customer_group: pageCustomerGroup,
      page_security_level: pageSecurityLevel,
      page_name: pageName === "" ? "pws:" + pageCategory : "pws:" + pageCategory + ":" + pageName,
      page_category: pageCategory,
      page_subcategory: pageSubCategory,
      raw_datalayer: rawDataLayer,
    };
    
    if (!window.tealium_qa_log) {
      window.tealium_qa_log = [];
    }
    window.tealium_qa_log.push({
      site_country: siteCountry,
      tealium_event: tealiumEvent,
      page_url: pageUrl,
      event_category: eventCategory,
      event_action: eventAction,
      event_content: eventContent,
      site_section: siteSection,
      page_language: pageLanguage,
      page_type: pageType,
      page_customer_group: pageCustomerGroup,
      page_security_level: pageSecurityLevel,
      page_name: pageName === "" ? "pws:" + pageCategory : "pws:" + pageCategory + ":" + pageName,
      page_category: pageCategory,
      page_subcategory: pageSubCategory,
      raw_datalayer: rawDataLayer,
    });

    if (window.tealium_qa_log.length > 4) {
      const arrSize = window.tealium_qa_log.length;

      window.tealium_qa_log = window.tealium_qa_log.slice(arrSize - 5, arrSize);
    }

    window.utag.link(window.utag_data);
  }
};
