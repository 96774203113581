import React from "react";
import AnchorList from "../anchor-list/anchor-list.component";

const Resources = ({ linkItems = [], downloadableItems, isProductFinder }) => (
  <section className="resources">
    {linkItems.length > 0 && <AnchorList anchorItems={linkItems} isLink={true} />}
    {downloadableItems.length > 0 && (
      <>
        <AnchorList
          additionalClassName="download-list"
          anchorItems={downloadableItems}
          isLink={true}
          isProductFinder={isProductFinder}
        />
      </>
    )}
    {downloadableItems.length < 2 && isProductFinder && (
      <div className="description">
        <p>
          For historic rates, please click <a href="/newsroom">here</a> and select the relevant date to view the pdf
          rate sheet. Please note that historical rate sheets older than 25.10.22 are not available to view.
        </p>
      </div>
    )}
  </section>
);

export default Resources;
