/* import PromoImage1 from "../assets/images/promo_1.png"; */
import PopupHeroTransparentImg from "../assets/images/popup-hero-transparent.png";

/* export const POPUP_DATA = {
  title:
    "Have you seen our Energy Efficient Home (EEH) cashback products?\nCashback incentives range from £750 - £1,750",
  description:
    "some foot note some foot note some foot note some foot note some foot note some foot note some foot note",
  image: PromoImage1,
  variant: "promo1",
  link: "/energy-efficient-home"
}; */

export const POPUP_DATA = {
  title: "",
  description: "",
  image: PopupHeroTransparentImg,
  variant: "promo2",
  link: "/pdfs/Packaging_and_process_final.pdf",
  name: "Packaging and process useful guide popup",
  titleAboveImage: "Want a quicker 'Application to Offer'?",
  titleBelowImage: "Check out our useful guide for hints and tips to help your cases be underwritten first time.",
};
