import React from "react";
import ExternalLink from "../external-link/external-link.component";
import NewWindow from "../../assets/new_window.inline.svg";

const FindYourBdm = ({ additionalClassname, onClick }) => {
  // const menuContext = useContext(MenuContext);

  return (
    <section
      className={
        additionalClassname
          ? `find-your-bdm find-your-bdm--${additionalClassname}`
          : "find-your-bdm find-your-bdm--small-box"
      }
    >
      {additionalClassname ? (
        <>
          <h3>Accessibility and disability</h3>
          <p>Supporting your customer's everyday banking needs.</p>
          <p>
            Once you have submitted an application, you can tell us about any additional support needs your customer may
            require by contacting our help desk on{" "}
            <a href="tel:03456005847" onClick={() => onClick("03456005847")}>
              0345 600 5847
            </a>{" "}
            (please ensure you have their consent prior to contacting us).
          </p>
          <p>We'll only ever use this information to provide a better banking service.</p>
          <p>
            For a full list of support services available, please click{" "}
            <a
              href="https://www.hsbc.co.uk/accessibility/"
              onClick={() => onClick("here")}
              target="_blank"
              className="link"
            >
              here
            </a>
            .
          </p>
        </>
      ) : (
        <>
          <h3>Find your BDM</h3>
          <p>
            Our experienced field and phone based Business Development Managers are on hand to provide you with
            dedicated support.
          </p>
          <p>
            To find out who your BDM is please use our ‘Chat with us’ facility, providing your name, firm and FCA
            reference.
          </p>
          <ExternalLink
            to="https://www.hsbc.co.uk/mortgages/intermediaries/help/"
            target="_blank"
            className="general-anchor-btn live-chat-btn"
            onClick={() => onClick("Chat with us")}
          >
            Chat with us
            <NewWindow />
          </ExternalLink>
        </>
      )}
    </section>
  );
};

export default FindYourBdm;
