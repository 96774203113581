import React, { useContext, useRef } from "react";
import { MenuContext } from "../../context";

const Accordion = ({ additionalClass, id = "", title, children, target, onClick }) => {
  const accordionContainer = useRef(null);
  const accordionBody = useRef(null);
  const menuContext = useContext(MenuContext);

  const handleClick = () => {
    if (onClick) {
      onClick(title);
    }
  };

  return (
    <details id={id} className="accordion" inert={menuContext.isMenuOpen ? "true" : null}>
      <summary className="accordion__head" onClick={handleClick}>
        <h3>
          {title}
          {target && <span id={target} style={{ visibility: "hidden" }}></span>}
        </h3>
      </summary>
      <div className="accordion__container" ref={accordionContainer}>
        <div className={`${(additionalClass && additionalClass) || ""} accordion__body`} ref={accordionBody}>
          {children}
        </div>
      </div>
    </details>
  );
};

export default Accordion;
