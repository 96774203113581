const WHAT_HAS_CHANGED_FILTER = [
  {
    title: "December 2024",
    lastUpdatedAt: "december",
    noChange: true,
    stressRate: false,
    variableIncome: false,
    hasPdf: false,
    offerExtension: false,
    links:false,
  },
  {
    title: "November 2024",
    lastUpdatedAt: "november_2024",
    noChange: true,
    stressRate: false,
    variableIncome: false,
    loanToIncome: false,
    productSwitch: false,
    remortgage: false,
    hasPdf: false,
    offerExtension: false,
    links: false,
    link1:"Flats and new builds",
    link1Href:"/pdfs/Aug_2024_Flats_New_Builds.pdf",
  },
  {
    title: "October 2024",
    lastUpdatedAt: "october_2024",
    noChange: false,
    stressRate: false,
    variableIncome: false,
    loanToIncome: false,
    productSwitch: false,
    remortgage: false,
    hasPdf: false,
    offerExtension: false,
    links:true,
    link1:"Capitalising fees",
    link1Href:"/pdfs/Capitalising_Fees.pdf",
  },
];

export default WHAT_HAS_CHANGED_FILTER;

// What Has Changed utils for criteria filter
export const WHC_MONTHS = WHAT_HAS_CHANGED_FILTER.map((d) => d.title);

export const WHC_TERMS = WHAT_HAS_CHANGED_FILTER.map((d) => d.lastUpdatedAt);

export const getTermByTitle = (title) => termByTitle[title];

export const getTitleByTerm = (term) => titleByTerm[term];

let titleByTerm = Object.assign({}, ...WHAT_HAS_CHANGED_FILTER.map((item) => ({ [item.lastUpdatedAt]: item.title })));

let termByTitle = Object.assign({}, ...WHAT_HAS_CHANGED_FILTER.map((item) => ({ [item.title]: item.lastUpdatedAt })));
