import React, { useContext } from "react";
import { MenuContext } from "../../context";

const MoneyWorriesBtn = ({ onClick }) => {
  const menuContext = useContext(MenuContext);
  return (
    <section className="money-worries-btn ">
      <a
        href="https://www.hsbc.co.uk/financial-fitness/rising-cost-of-living/"
        onClick={() => onClick("The rising costs of living")}
        target="_blank"
        inert={menuContext.isMenuOpen ? "true" : null}
      >
        <h3>
          <strong>The rising costs of living</strong>
        </h3>
        <p className="money-worries-btn-p">How to help your customer</p>
      </a>
    </section>
  );
};

export default MoneyWorriesBtn;
