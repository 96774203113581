import React from "react";

import GeneralAnchor from "../general-anchor/general-anchor.component";
import DownloadIcon from "../../assets/download.inline.svg";

const AnchorList = ({ ariaLabel, anchorItems, additionalClassName, isLink, isBlank, isProductFinder }) => (
  <ul className={`${additionalClassName || ""}`}>
    {anchorItems.length &&
      anchorItems.map(({ downloadTitle, isDownload, title, ...rest }, index) => (
        <li className="anchor-item" key={index}>
          <GeneralAnchor
            isLink={isLink}
            ariaLabel={ariaLabel}
            downloadTitle={downloadTitle}
            isDownload
            {...rest}
            isBlank
          >
            <span className={`title ${downloadTitle || isDownload ? "title--download" : ""}`}>
              {(downloadTitle || isDownload) && <DownloadIcon />} {title}
            </span>
            {isProductFinder && <img src="../../assets/external.link.svg" alt="Ext" />}
          </GeneralAnchor>
        </li>
      ))}
  </ul>
);

export default AnchorList;
